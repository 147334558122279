import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import "./AboutContent.css"
const query = graphql`
  {
    file(relativePath: { eq: "about-img.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const AboutComponent = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <section className="assets about-container">
      <div className="container row center-align">
        <h4 className="title">What I've Been up to...</h4>
        <hr />
        <div className="col s10 m5 offset-s1 offset-m0 about-img-container">
          <Image fluid={fluid} />
        </div>
        <div className="col s12 m7 about-text-container left-align">
          <p>
            So alot of you guys have been asking me what I have been doing since
            I stopped playing ball. Well, I have literaly been trying to
            optimize my health. I may not be an expert chef, but I am an expert
            in what to put in to your body to acheive your peak athletic
            potential. ***More Text Will Go Here*** Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Praesentium placeat, quisquam illo
            delectus labore nulla soluta veritatis dolores! Iusto perspiciatis,
            iste cupiditate laboriosam doloribus commodi quo illo dolores quis
            incidunt excepturi ipsam voluptatem. Architecto facere reiciendis
            non laborum amet! Numquam optio, blanditiis velit unde odit
            laudantium in consectetur ut quo molestiae similique totam maxime
            soluta eius rerum neque provident ipsam dolorem incidunt architecto
            ad libero sit exercitationem esse. Minus veritatis iure explicabo
            quas vitae possimus, quaerat unde doloribus, asperiores distinctio
            quo dolorum reprehenderit corrupti omnis amet dicta perspiciatis
            modi, inventore incidunt vero sapiente obcaecati ut. In beatae rem
            modi harum?
          </p>
        </div>
      </div>
    </section>
  )
}
export default AboutComponent
