import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Hero from "../components/Hero"
import AboutContent from "../components/AboutContent"
import SEO from "../components/SEO"
export default ({ data }) => {
  const {
    file: {
      childImageSharp: { fluid: hero },
    },
  } = data
  return (
    <Layout>
      <SEO title="About" description="The man behind the brand" />
      <Hero
        height="40vh"
        title="About"
        desc="more than football"
        fluid={hero}
      />
      <AboutContent />
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "aux1.PNG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
